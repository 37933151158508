.logo_wrapper {
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.screen {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #55198b;  -- Uncomment if you want a background color */
  overflow: hidden;
  border-radius: 100%;
  animation: grow 3.5s forwards;
  position: relative;
}

body {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(1);
    border-radius: 0%;
    height: 100%;
    width: 100%;
  }
  90% {
    transform: scale(1);
    border-radius: 0%;
    height: 100%;
    width: 100%;
  }
  100% {
    transform: scale(0);
    transform-origin: 50% 50%;
    border-radius: 100%;
  }
}

.splash-title-container {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-decoration: none;
}

.splash-title {
  font-family: "Brittany", "Agustina Regular", cursive;
  font-weight: bold;
  font-size: 2.5vw;
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  padding: 0 20px;
}

#logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
